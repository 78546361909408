import PropTypes from 'prop-types';
import React from 'react';
import {SortDirections} from "Route/CategoryPage/CategoryPage.config";
import './VariantProductList.style';

/** @namespace VusaApp/Component/VariantProductList/Component */
export class VariantProductListComponent extends React.Component {
    static propTypes = {
        items: PropTypes.arrayOf(Object).isRequired,
        configurableOptions: PropTypes.object.isRequired,
        productQuantities: PropTypes.object.isRequired,
        quantityChangeHandler: PropTypes.func.isRequired,
        clickActiveProduct: PropTypes.func.isRequired,
    };

    state = {
        currentSort: '',
        direction: SortDirections.ASC,
        renderedAttribute: '',
        attributePerPage: '10',
        attributeSearch: '',
        attributePage: 1,
    }

    handleTableSortClick(attribute) {
        const {
            items
        } = this.props;
        let {
            currentSort,
            direction
        } = this.state;

        if (attribute === currentSort) {
            direction = direction === SortDirections.ASC ? SortDirections.DESC : SortDirections.ASC;
        } else {
            direction = SortDirections.ASC;
        }
        this.setState({currentSort: attribute, direction: direction});
        switch (attribute) {
            case "salable_qty":
                this.handleTableSortBySalableQty(items, attribute, direction);
                break;
            case "price":
                this.handleTableSortByPrice(items, direction);
                break;
            case "subtotal":
                this.handleTableSortBySubtotal(items, direction);
                break;
            case "qty":
                this.handleTableSortByQty(items, direction);
                break;
            default:
                this.handleTableSortByAttribute(items, attribute, direction);
        }

    }

    handleTableSortByAttribute(items, attribute, direction) {
        const {
            configurableOptions
        } = this.props;
        const attrOptions = configurableOptions[attribute].attribute_options;
        if (direction === SortDirections.ASC) {
            items.sort(function (a, b) {
                const aLabel = attrOptions[a.attributes[attribute].attribute_value].label,
                    bLabel = attrOptions[b.attributes[attribute].attribute_value].label;
                return aLabel.localeCompare(bLabel);
            });
        } else {
            items.sort(function (a, b) {
                const aLabel = attrOptions[a.attributes[attribute].attribute_value].label,
                    bLabel = attrOptions[b.attributes[attribute].attribute_value].label;
                return bLabel.localeCompare(aLabel);
            });
        }
    }

    handleTableSortBySalableQty(items, currenSort, direction) {
        if (direction === SortDirections.ASC) {
            items.sort(function (a, b) {
                return a[currenSort] - b[currenSort]
            });
        } else {
            items.sort(function (a, b) {
                return b[currenSort] - a[currenSort]
            });
        }
    }

    handleTableSortByPrice(items, direction) {
        if (direction === SortDirections.ASC) {
            items.sort(function (a, b) {
                return a.price_range.maximum_price.default_final_price.value
                    - b.price_range.maximum_price.default_final_price.value
            });
        } else {
            items.sort(function (a, b) {
                return b.price_range.maximum_price.default_final_price.value
                    - a.price_range.maximum_price.default_final_price.value
            });
        }
    }

    handleTableSortBySubtotal(items, direction) {
        const {
            productQuantities
        } = this.props;
        if (direction === SortDirections.ASC) {
            items.sort(function (a, b) {
                return a.price_range.maximum_price.default_final_price.value * productQuantities[a.id]
                    - b.price_range.maximum_price.default_final_price.value * productQuantities[b.id]
            });
        } else {
            items.sort(function (a, b) {
                return b.price_range.maximum_price.default_final_price.value * productQuantities[b.id]
                    - a.price_range.maximum_price.default_final_price.value * productQuantities[a.id]
            });
        }
    }

    handleTableSortByQty(items, direction) {
        const {
            productQuantities
        } = this.props;
        if (direction === SortDirections.ASC) {
            items.sort(function (a, b) {
                return productQuantities[a.id] - productQuantities[b.id]
            });
        } else {
            items.sort(function (a, b) {
                return productQuantities[b.id] - productQuantities[a.id]
            });
        }
    }

    handleAttributesFilter() {
        const {items, configurableOptions} = this.props;
        const {
            attributePerPage,
            attributeSearch,
            attributePage
        } = this.state;
        let query = attributeSearch.toLowerCase(),
            queries = query.trim().split(" "),
            unmatchedAttributes = [],
            hideAttributes = [];
        const attributes = Object.keys(configurableOptions);

        items.map(function (item) {
            let label, attributeMatched = false;

            attributes.every(function (attribute) {
                label = item.attributes[attribute] ? configurableOptions[attribute].attribute_options[item.attributes[attribute].attribute_value].label : '';
                label = label.toLowerCase();
                if (queries.every(v => label.includes(v))) {
                    attributeMatched = true;
                    return false;
                }

                return true;
            });

            if (!item.sku.toLowerCase().includes(query) && !attributeMatched) {
                unmatchedAttributes.push(item.id);
            }
        });

        let index = 0,
            pageMin = attributePerPage * (attributePage - 1),
            pageMax = attributePerPage * attributePage;
        items.map(function (item) {
            if (unmatchedAttributes.indexOf(item.id) > -1) {
                return;
            } else if (index < pageMin || index >= pageMax) {
                hideAttributes.push(item.id);
            }
            index++;
        });
        return [unmatchedAttributes, hideAttributes];
    }

    handleAttributePerPage(e) {
        this.setState({
            attributePerPage: e.target.value || 20,
            attributePage: 1
        });
    }

    handleAttributeSearch(e) {
        this.setState({
            attributeSearch: e.target.value,
            attributePage: 1
        });
    }

    renderSortingIndicator(attribute) {
        const {
            currentSort,
            direction
        } = this.state;

        return (
            <div className={currentSort == attribute ? "active" : ""}>
                <button className={direction == SortDirections.ASC ? "active" : ""}>▲</button>
                <button className={"down " + (direction == SortDirections.DESC ? "active" : "")}>▼</button>
            </div>
        );
    }

    renderSearchBar() {
        const {attributeSearch} = this.state;
        return (
            <div id="orderTable_filter" className="dataTables_filter">
                <label>Search:<input type="search" onChange={this.handleAttributeSearch.bind(this)}
                                     value={attributeSearch}></input></label>
            </div>
        );
    }

    renderAttributeNo() {
        const {
            attributePerPage
        } = this.state;

        return (
            <div className="dataTables_length" id="orderTable_length">
                <label>Show
                    <select name="orderTable_length" value={attributePerPage}
                            onChange={this.handleAttributePerPage.bind(this)}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select> items</label>
            </div>
        );
    }

    renderAttributeNoAndSearchBar() {
        return (
            <div className="attribute-no-search-bar-wrapper">
                {this.renderAttributeNo()}
                {this.renderSearchBar()}
            </div>
        );
    }

    renderShowingItemsInfo(unmatchedAttributes) {
        const {
            attributePerPage,
            attributePage
        } = this.state;

        const {
            items
        } = this.props;

        let pageMin = attributePerPage * (attributePage - 1),
            pageMax = attributePerPage * attributePage,
            total = items.length - unmatchedAttributes.length;
        pageMax = Math.min(pageMax, total);
        return (
            <div className="dataTables_info" id="orderTable_info" role="status" aria-live="polite">
                Showing {pageMin + 1} to {pageMax} of {total} items
                {unmatchedAttributes.length ? " (filtered from " + items.length + " total items)" : ''}
            </div>
        );
    }

    handlePageChange(page) {
        this.setState({attributePage: page});
    }

    renderPager(unmatchedAttributes) {
        const {
            attributePerPage,
            attributePage
        } = this.state;

        const {
            items
        } = this.props;
        let totalPage = Math.ceil((items.length - unmatchedAttributes.length) / attributePerPage);
        if (totalPage <= 1) {
            return null;
        }
        const pages = [];
        for (let i = 1; i <= totalPage; i++) {
            pages.push((
                <span key={i}>
                    <a key={i} className={"paginate_button " + (i == attributePage ? 'current' : '')}
                       role="link" tabIndex={i} onClick={this.handlePageChange.bind(this, i)}>{i}</a>
                </span>
            ));
        }
        let disabledPreviousBtn = attributePage == 1,
            disabledNextBtn = attributePage == totalPage;
        return (
            <div key={"orderTable_paginate"} className="dataTables_paginate paging_simple_numbers"
                 id="orderTable_paginate">
                <a key={"orderTable_paginate_previous"}
                   className={"paginate_button previous " + (disabledPreviousBtn ? 'disabled' : '')} role="link"
                   id="orderTable_previous" tabIndex={disabledPreviousBtn ? "-1" : "0"}
                   onClick={this.handlePageChange.bind(this, attributePage - 1)}>Previous</a>
                {pages}
                <a key={"orderTable_paginate_next"}
                   className={"paginate_button next " + (disabledNextBtn ? 'disabled' : '')} role="link"
                   id="orderTable_next" tabIndex={disabledNextBtn ? "-1" : totalPage + 1}
                   onClick={this.handlePageChange.bind(this, attributePage + 1)}>Next</a>
            </div>
        );
    }

    renderShowingItemsAndPager(unmatchedAttributes) {
        return (
            <div className="showing-items-pager">
                {this.renderShowingItemsInfo(unmatchedAttributes)}
                {this.renderPager(unmatchedAttributes)}
            </div>
        );
    }

    render() {
        // eslint-disable-next-line max-len
        const {
            clickActiveProduct, items, configurableOptions, productQuantities, quantityChangeHandler, quantityKeyChangeHandler
        } = this.props;

        const attributes = Object.keys(configurableOptions);
        const [unmatchedAttributes, hideAttributes] = this.handleAttributesFilter();

        return (
            <div className="variants-table-content" key={"variants-table-content"}>
                {this.renderAttributeNoAndSearchBar()}
                <div block="table-responsive" key={"variants-table-responsive"}>
                    <table block="table" key={"variants-table"}>
                        <thead key={"variants-table-thead"}>
                        <tr key={"variants-table-thead-row"}>
                            {attributes.map((item) => <th className="attribute-header"
                                                          onClick={this.handleTableSortClick.bind(this, item)}
                                                          key={item}>{configurableOptions[item].attribute_label}
                                {this.renderSortingIndicator(item)}
                            </th>)}
                            <th key={"salable_qty"}
                                onClick={this.handleTableSortClick.bind(this, "salable_qty")}>Availability
                                {this.renderSortingIndicator("salable_qty")}
                            </th>
                            <th key={"price"} onClick={this.handleTableSortClick.bind(this, "price")}>Unit Price
                                {this.renderSortingIndicator("price")}
                            </th>
                            <th key={"subtotal"} onClick={this.handleTableSortClick.bind(this, "subtotal")}>Subtotal
                                {this.renderSortingIndicator("subtotal")}
                            </th>
                            <th key={"qty"} onClick={this.handleTableSortClick.bind(this, "qty")}>Qty
                                {this.renderSortingIndicator("qty")}
                            </th>
                        </tr>
                        </thead>
                        <tbody key={"variants-table-tbody"}>
                        {items.map(function (item) {
                            if (unmatchedAttributes.indexOf(item.id) > -1 || hideAttributes.indexOf(item.id) > -1) {
                                return null;
                            }
                            return (
                                <tr key={item.id}>
                                    {attributes.map((attribute, index) => {
                                        if (!item?.attributes[attribute]?.attribute_value) {
                                            return <td/>;
                                        }
                                        let label = item.attributes[attribute] ?
                                            configurableOptions[attribute].attribute_options[item.attributes[attribute].attribute_value].label : '';

                                        return (
                                            <td key={attribute}>
                                                { /* eslint-disable-next-line max-len */}
                                                <button
                                                    block={index === 0 ? 'btn' : 'btn-secondary'}
                                                    onClick={clickActiveProduct}
                                                    data-code={attribute}
                                                    data-value={item.attributes[attribute].attribute_value ?? ''}
                                                    title={label}
                                                >
                                                    {label}
                                                </button>
                                            </td>
                                        );
                                    })}
                                    <td key={"salable_qty"}>
                                        {item.salable_qty}
                                    </td>
                                    <td key={"price"}>
                                        $
                                        {item.price_range ? item.price_range.maximum_price.default_final_price.value : 0}
                                    </td>
                                    <td key={"subtotal"}>
                                        $
                                        { /* eslint-disable-next-line max-len */}
                                        {((item.price_range ? item.price_range.maximum_price.default_final_price.value : 0) * productQuantities[item.id]).toFixed(2)}
                                    </td>
                                    <td key={"qty"}>
                                        <input
                                            type="number"
                                            value={ productQuantities[item.id] === "" ? "" : Number(productQuantities[item.id]) }
                                            data-id={item.id}
                                            onChange={quantityChangeHandler}
                                            onKeyPress={quantityKeyChangeHandler}
                                            style={{width: '70px', textAlign: 'center'}}
                                            disabled={item.salable_qty === 0}
                                            key={"qty-input"}
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                {this.renderShowingItemsAndPager(unmatchedAttributes)}
            </div>
        );
    }
}

export default VariantProductListComponent;
